import tierList from "@core/services/common/lists/graphql/tierList.graphql";
import { TierListResponse } from "@core/store/types/common/lists/TierList";
import Service from "@core/services/common/Service";

export class TierListService extends Service {
  public static async getTierList (): Promise<TierListResponse> {
    try {
      return await this.api.get("", {
        params: {
          query: tierList
        }
      });
    } catch (e) {
      throw e;
    }
  }
}