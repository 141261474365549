import { TierListStore } from "@core/store/types/common/lists/TierList";
import { defineStore } from "pinia";
import { TierListService } from "@core/services/common/lists/TierListService";

export const tierList = defineStore("tierList", {
  state: (): TierListStore => ({
    data: {
      items: [],
      count: null
    }
  }),

  actions: {
    async GET_TIER_LIST () {
      const { data: { tiers: items } } = await TierListService.getTierList();
      this.data = { items, count: items.length };
    }
  }
});