<template>
    <Select
        v-model="selectedTier"
        v-bind="{ ...$attrs, ...$props }"
        :hide-null="hideNull"
        :get-data-vuex="getFilteredTier"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :loading="isLoading"
        class="white"
        field="name"
        :multiple="multiple">
    </Select>
</template>

<script>
  import BaseSelect from "@/components/Common/Base/BaseSelect.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import { tierList } from "@/stores/common/lists/tierList";
  import { useWait } from "@/stores/common/waitStore";
  import { storeToRefs } from "pinia";

  export default {
    name: "TierSelect",
    extends: BaseSelect,
    components: { Select },

    setup () {
      const tierListUse = tierList();
      const { data } = storeToRefs(tierListUse);

      const isLoading = useWait(tierListUse, "GET_TIER_LIST");

      return { data, tierListUse, isLoading };
    },

    computed: {
      selectedTier: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit("input", value);
        }
      }
    },

    methods: {
      async getFilteredTier () {
        if (this.data.items.length === 0) {
          await this.tierListUse.GET_TIER_LIST();
        }
        return this.data;
      }
    },

    destroyed () {
      this.tierListUse.$reset();
    }
  };
</script>

<style scoped>
</style>
